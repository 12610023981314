var render, staticRenderFns
import script from "./table.vue?vue&type=script&lang=js"
export * from "./table.vue?vue&type=script&lang=js"
import style0 from "./table.vue?vue&type=style&index=0&id=5752718c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5752718c",
  null
  
)

export default component.exports