import server from '../request';

export const cooperationSubscriptionPageList = (params) => server.post('/xiaozi-xmo/wireless/saas/cooperationSubscription/pageList', params);

export const cooperationSubscriptionSubscribe = (params) => server.post('/xiaozi-xmo/wireless/saas/cooperationSubscription/subscribe', params);

export const cooperationSubscriptionCancel = (params) => server.post('/xiaozi-xmo/wireless/saas/cooperationSubscription/cancel', params);

export const cooperationEnterprisePageList = (params) => server.post('/xiaozi-xmo/wireless/saas/cooperationEnterprise/pageList', params);

export const cooperationEnterpriseClose = (params) => server.post('/xiaozi-xmo/wireless/saas/cooperationEnterprise/close', params);

export const cooperationEnterpriseOpen = (params) => server.post('/xiaozi-xmo/wireless/saas/cooperationEnterprise/open', params);
